import React from 'react';
import logoAbb from '../assets/images/fornitori_abb.png'
import logoBticino from '../assets/images/fornitori_bticino.png'
import logoBeghelli from '../assets/images/fornitori_beghelli.png'
import logoElkron from '../assets/images/fornitori_elkron.png'
import logoGewiss from '../assets/images/fornitori_gewiss.png'
import logoHikvision from '../assets/images/fornitori_hikvision.png'
import logoNotifier from '../assets/images/fornitori_notifier.png'
import logoSchneider from '../assets/images/fornitori_schneider.png'
import logoSiemens from '../assets/images/fornitori_siemens.png'
import logoDuemmegi from '../assets/images/fornitori_duemmegi.png'
import logoFranke from '../assets/images/fornitori_franke.png'
import logoHki from '../assets/images/fornitori_hki.png'


const imgFornitori = [
    {
        nome: 'ABB',
        url: logoAbb
    },{
        nome: 'BTicino',
        url: logoBticino
    },{
        nome: 'Beghelli',
        url: logoBeghelli
    },{
        nome: 'Elkron',
        url: logoElkron
    },{
        nome: 'Gewiss',
        url: logoGewiss
    },{
        nome: 'Hikvision',
        url: logoHikvision
    },{
        nome: 'Notifier',
        url: logoNotifier
    },{
        nome: 'Schneider',
        url: logoSchneider
    },{
        nome: 'Siemens',
        url: logoSiemens
    },{
        nome: 'Duemmegi',
        url: logoDuemmegi
    },{
        nome: 'FRANKE',
        url: logoFranke
    },{
        nome: 'H&K Manufacturing',
        url: logoHki
    }
]

const Fornitori = () => {
    return (
        <div>
            {imgFornitori.map((fornitore) => {
                return <img src={fornitore.url} alt={`logo ${fornitore.nome}`} />
            })}
        </div>
        )
}

export default Fornitori;