import React from "react";
import Scrollspy from "react-scrollspy";
import Scroll from "./Scroll";

const Nav = (props) => (
  <nav id="nav" className={props.sticky ? "alt" : ""}>
    <Scrollspy
      items={["intro", "first", "clienti", "fornitori"]}
      currentClassName="is-active"
      offset={-300}
    >
      <li>
        <Scroll type="id" element="intro">
          <a>Chi Siamo</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="first">
          <a>Servizi</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="clienti">
          <a>Clienti</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="fornitori">
          <a>Partner</a>
        </Scroll>
      </li>
    </Scrollspy>
  </nav>
);

export default Nav;
