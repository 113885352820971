import { Link } from "gatsby";
import React from "react";
import { Waypoint } from "react-waypoint";
import pic02 from "../assets/images/sede_giuvi_nuova.jpg";
import Layout from "../components/layout";
import Nav from "../components/Nav";
import Clienti from "../components/Clienti";
import Fornitori from "../components/Fornitori";
import Header from "../components/Header";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: false,
    };
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }));
  };

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  };

  render() {
    return (
      <Layout>
        <Header />
        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Chi Siamo</h2>
                </header>
                <p>
                  Giuvi nasce nel 2012 con l'obiettivo di soddisfare ogni
                  tipologia di richiesta in ambito elettrotecnico ed elettronico
                  dalla realizzazione di nuovi impianti alla manutenzione degli
                  esistenti. L’attenzione dedicata al cliente e la dedizione al
                  lavoro ci hanno permesso di distinguerci per qualità,
                  versatilità e velocità nel servizio. Le opportunità ci hanno
                  permesso nel corso del tempo di sviluppare oltre al settore
                  tecnologico anche i settori edili e meccanici oltre che il
                  food service. Ad oggi Giuvi garantisce attività di global
                  service e facility management con la mission di eccellere nel
                  mercato grazie alla professionalità e la competenza che ci
                  hanno permesso di crescere in maniera concreta e costante.
                </p>
                <ul className="actions">
                  <li>
                    <Link to="/chi_siamo" className="button">
                      Maggiori Informazioni
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/company/giuvi-srl/"
                      className="button"
                    >
                      Seguici su LinkedIn
                    </Link>
                  </li>
                </ul>
              </div>
              <span className="image">
                <img src={pic02} alt="" />
              </span>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>DI COSA CI OCCUPIAMO</h2>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-plug"></span>
                <h3>IMPIANTI ELETTRICI</h3>
                <p>
                  Siamo specializzati nella progettazione, realizzazione e
                  manutenzione di Impianti Elettrici e Speciali in ambito civile
                  ma soprattutto industriale. Tra i nostri clienti possiamo
                  vantare alcune delle più grandi realtà specializzate nella
                  logistica che servono le più importanti aziende farmaceutiche
                  mondiali.
                </p>
                <ul style={{ margin: "0 auto", listStyle: "none" }}>
                  <li>
                    <Link to="impiantiElettrici" className="button">
                      Maggiori Informazioni
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <span className="icon major style5 fa-building"></span>
                <h3>FACILITY MANAGEMENT</h3>
                <p>
                  Proponiamo pacchetti manutentivi personalizzati in base alle
                  esigenze specifiche del cliente. Operiamo su immobili di
                  qualsiasi target, il nostro Team possiede le competenze
                  necessarie per poter far fronte ad ogni tipologia di
                  richiesta.
                </p>
                <ul style={{ margin: "0 auto", listStyle: "none" }}>
                  <li>
                    <Link to="/facilityManagement" className="button">
                      Maggiori Informazioni
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <span className="icon major style3 fa-fire"></span>
                <h3>FOODSERVICE KITCHEN EQUIPMENT ASSISTANCE</h3>
                <p>
                  Effettuiamo interventi in pronto intervento di manutenzione e
                  riparazione di elettrodomestici industriali per i più
                  importanti marchi di Fast Food, Ristoranti, Hotel, Bar. Tra i
                  nostri maggiori Clienti i ristoranti delle catene McDonalds e
                  Burger King.
                </p>
                <ul style={{ margin: "0 auto", listStyle: "none" }}>
                  <li>
                    <Link to="/equipmentCucine" className="button">
                      Maggiori Informazioni
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            {/* 
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer>
            */}
          </section>
          {/* 
          <section id="second" className="main special">
            <header className="major">
              <h2>I NOSTRI NUMERI</h2>
              <p>
                Il nostro percorso di crescita è in continua evoluzione.
                <br />
                Lavoriamo affinché questi numeri siano sempre in aumento.
              </p>
            </header>
            <ul className="statistics">
              <li className="style1">
                <span className="icon fa-address-book"></span>
                <strong>50+</strong> Clienti ad oggi
              </li>
              <li className="style2">
                <span className="icon fa-folder-open-o"></span>
                <strong>300+</strong> Clienti negli ultimi 8 anni
              </li>
              <li className="style3">
                <span className="icon fa-signal"></span>
                <strong>10'000+</strong> Lavori negli ultimi 8 anni
              </li>
              <li className="style4">
                <span className="icon fa-laptop"></span>
                <strong>30+</strong> Partners e Collaboratori
              </li>
            </ul>
          </section>
           */}

          <section id="clienti" className="main special">
            <header className="major">
              <h2>I NOSTRI CLIENTI</h2>
            </header>
            <p className="content">
              I clienti sono al centro del nostro business, è per noi di
              fondamentale importanza l’ascolto delle loro esigenze. Questo ci
              permette di comprendere i problemi da risolvere nella loro
              complessità e una volta compresi, di mettere in campo quelle che
              sono le nostre armi: la nostra competenza, l’eccellenza dei
              materiali e dei servizi offerti. In questo modo siamo in grado di
              sviluppare in concerto con le esigenze del cliente, il modo più
              rapido, completo ed economico per raggiungere il risultato atteso.
              Qui di seguito un estratto di alcuni dei nostri principali
              Clienti:
            </p>
            <footer className="major">
              <ul className="actions">
                <li>
                  {/** 
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                  */}
                  <Clienti />
                </li>
              </ul>
            </footer>
          </section>
          <section id="fornitori" className="main special">
            <header className="major">
              <h2>I NOSTRI PARTNERS</h2>
            </header>
            <p className="content">
              I nostri Partners sono tutte aziende leader di mercato. Nella
              nostra cultura aziendale è presente la selezione accurata dei
              nostri Partners, questo ci permette di offrire ai nostri clienti
              la massima qualità disponibile sul mercato, ma soprattutto
              garantire loro la longevità delle soluzioni proposte e realizzate.
              Qui di seguito un estratto di alcuni di nostri principali
              Partners:
            </p>
            <footer className="major">
              <ul className="actions">
                <li>
                  {/** 
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                  */}
                  <Fornitori />
                </li>
              </ul>
            </footer>
          </section>
        </div>
      </Layout>
    );
  }
}

export default Index;
