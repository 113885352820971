import React from "react";
import logoFiege from "../assets/images/clienti_fiege.png";
import logoIntesa from "../assets/images/clienti_intesa.png";
import logoSielte from "../assets/images/clienti_sielte.png";
import logoChiapparoli from "../assets/images/clienti_chiapparoli.png";
import logoStm from "../assets/images/clienti_stm.png";
import logoZalando from "../assets/images/clienti_zalando.png";
import logoBk from "../assets/images/clienti_bk.png";
import logoMcD from "../assets/images/clienti_mc.png";
import logoMD from "../assets/images/clienti_md.png";
import logoBreitling from "../assets/images/clienti_breitling.png";
import logoRedbull from "../assets/images/clienti_redbull.png";
import logoTog from "../assets/images/clienti_tog.png";

const imgClienti = [
  {
    nome: "Fiege",
    url: logoFiege,
  },
  {
    nome: "Intesa",
    url: logoIntesa,
  },
  {
    nome: "Chiapparoli",
    url: logoChiapparoli,
  },
  {
    nome: "STM",
    url: logoStm,
  },
  {
    nome: "Zalando",
    url: logoZalando,
  },
  {
    nome: "Burger King",
    url: logoBk,
  },
  {
    nome: "McDonalds",
    url: logoMcD,
  },
  {
    nome: "MD",
    url: logoMD,
  },
  {
    nome: "Breitling",
    url: logoBreitling,
  },
  {
    nome: "Redbull",
    url: logoRedbull,
  },
  {
    nome: "Fondazione Tog",
    url: logoTog,
  },
];

const Clienti = () => {
  return (
    <div>
      {imgClienti.map((cliente) => {
        return <img src={cliente.url} alt={`logo ${cliente.nome}`} />;
      })}
    </div>
  );
};

export default Clienti;
